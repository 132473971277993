<template>
    <v-card height="600">
        <v-card-title class="pa-0">
            <v-toolbar flat dense color="yellow">
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    {{ formTitle }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn text @click="saveItem">Guarda</v-btn>
            </v-toolbar>
        </v-card-title>

        <v-card-text>
            <v-container grid-list-md>
                <v-row>
                    <v-layout wrap>
                        <v-flex xs12 sm6 md4>
                            <v-text-field v-model="item.nombre"
                                          label="Nombre">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 sm6 md4>
                            <v-text-field v-model="item.direccion"
                                          label="Dirección">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 sm6 md4>
                            <selectProvincia v-model="item.provincia.id"
                                             :texto="item.provincia.nombre"
                                             @texto="
                                                    item.provincia.nombre = $event
                                                ">
                            </selectProvincia>
                        </v-flex>

                        <v-flex xs12 sm6 md3>
                            <selectLocalidad v-model="item.localidad.id"
                                             :texto="item.localidad.nombre"
                                             :provinciaId="item.provincia.id"
                                             @texto="
                                                    item.localidad.nombre = $event
                                                ">
                            </selectLocalidad>
                        </v-flex>

                        <v-flex xs12 sm6 md2>
                            <v-text-field v-model="item.cuit"
                                          label="CUIT">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 sm6 md1>
                            <v-text-field v-model="item.abrv"
                                          label="Abrv">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 sm6 md3>
                            <v-text-field v-model="item.email"
                                          label="Email">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 sm6 md3>
                            <v-text-field v-model="item.emailTrack"
                                          label="Email-Track">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 sm6 md12>
                            <neConfig v-model="neCiaTramiteDestinoSoporte"
                                      :cliente="item.id">
                            </neConfig>
                        </v-flex>

                        <v-flex xs12 sm12 md12>
                            <neGrid v-model="neCiaTramiteDestinoSoporte"
                                    :cliente="item.id">
                            </neGrid>
                        </v-flex>
                    </v-layout>
                </v-row>
            </v-container>
        </v-card-text>

    </v-card>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    import neConfig from '@/components/NE/NEConfig';
    import neGrid from '@/components/NE/NEGrid';
    import selectLocalidad from '@/components/Selects/selectLocalidad';
    import selectProvincia from '@/components/Selects/selectProvincia';

    export default {
        name: 'clienteDialogo',
        data() {
            return {
                ne: false
            };
        },

        computed: {
            ...mapState('cliente', ['item']),

            formTitle() {
                return this.index === -1 ? 'Nuevo' : 'Edita';
            },

            neCiaTramiteDestinoSoporte() {
                return this.item.neCiaTramiteDestinoSoporte;
            }
        },

        methods: {
            ...mapActions('cliente', ['closeDialog', 'saveItem']),

            close() {
                this.closeDialog();
            }
        },

        created() { },

        components: {
            neConfig,
            neGrid,
            selectLocalidad,
            selectProvincia,
        }

    };
</script>
