var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-2 mt-2",attrs:{"headers":_vm.headers,"dense":"","items":_vm.itemsSelected,"disable-pagination":"","hide-default-footer":""},on:{"click:row":_vm.dialogChange},scopedSlots:_vm._u([{key:"item.icono",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(" "+_vm._s(_vm.icono(item.tramiteId))+" ")])]}},{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.nombre(item.tramiteId))+" ")]}},{key:"item.destino",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.destino(item.neDestinoId))+" ")]}},{key:"item.asunto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.asunto)+" ")]}},{key:"item.mensaje",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mensaje)+" ")]}},{key:"item.envia",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{model:{value:(item.envia),callback:function ($$v) {_vm.$set(item, "envia", $$v)},expression:"item.envia"}})]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{model:{value:(item.link),callback:function ($$v) {_vm.$set(item, "link", $$v)},expression:"item.link"}})]}}])}),_c('v-dialog',{attrs:{"max-width":"1000px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('AsuntoMensajeAdjuntoDialogo',{attrs:{"title":_vm.title,"asunto":_vm.asunto,"mensaje":_vm.mensaje,"direccionSoporte":_vm.direccionSoporte},on:{"closeDialog":_vm.dialogChange,"guardaInfo":function($event){return _vm.changeAsuntoMensaje($event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }