<template>
    <v-select v-model="value"
              :dense="dense"
              :disabled="disabled"
              :items="items"
              attach
              small-chips
              chips
              item-text="nombre"
              item-value="id"
              :label="label"
              @change="change"
              multiple
              menu-props="{ auto: true, botton: true, offsetY: false }">
        <template slot="item" slot-scope="data">
            <!-- HTML that describe how select should render items when the select is open -->
            <v-icon class="mr-5">{{data.item.icono}}</v-icon>
            {{ data.item.nombre }}
        </template>
        <template slot="selection" slot-scope="data">
            <v-chip small :color="bkColor(data)" @click.stop="click(data)">
                {{data.item.nombre}}
            </v-chip>
        </template>
    </v-select>
</template>

<script>
    export default {
        name: 'multipleSelect',
        props: [
            'dense',
            'disabled',
            "value",
            "items",
            "label",
            "selectedColor",
            "unselectedColor"
        ],

        data: function () {
            return {
                id: null,
                oldVal: this.value
            }
        },

        computed: {
            idSelected() {
                if (this.id == null && this.value.length > 0) {
                    this.id = this.value[0];
                    this.$emit('selectChanged', this.id);
                }
                if (this.value.length == 0) {
                    this.id = null;
                    this.$emit('selectChanged', this.id);
                }
                return this.id;
            }
        },

        watch: {
            value(val, old) {
                if (val.length == 0 && old.length != 0) {
                    this.id = null;
                    this.$emit('selectChanged', this.id);
                }
                this.oldVal = val;
            }
        },

        methods: {
            click(data) {
                this.id = data.item.id;
                this.$emit('selectChanged', this.id);
                this.$emit('ItemClick', this.id);
            },

            change(val) {
                var oldVal = this.oldVal
                //detect differences
                const diff = [
                    ...val.filter(x => !oldVal.includes(x)),
                    ...oldVal.filter(x => !val.includes(x))
                ]
                this.oldVal = val
                var deleted = []
                var inserted = []
                // detect inserted/deleted
                for (var i = 0; i < diff.length; i++) {
                    if (oldVal.indexOf(diff[i]) >= 0) {
                        deleted.push(diff[i])
                    } else {
                        inserted.push(diff[i])
                        this.id = diff[i];
                        this.$emit('selectChanged', this.id);
                    }
                }
                this.$emit("change", val)
                this.$emit("lastchange", diff, inserted, deleted);
            },

            bkColor(data) {
                if (data.item.id == this.idSelected)
                    return this.selectedColor == undefined ? "gray" : this.selectedColor;
                return this.unselectedColor == undefined ? "gray" : this.unselectedColor;
            }
        }
    }
</script>

