<template>
    <div>
        <v-data-table :headers="headers"
                      dense
                      :items="itemsSelected"
                      disable-pagination
                      hide-default-footer
                      class="elevation-2 mt-2"
                      @click:row="dialogChange">

            <template v-slot:item.icono="{ item }">
                <v-icon>
                    {{ icono(item.tramiteId) }}
                </v-icon>
            </template>

            <template v-slot:item.nombre="{ item }">
                {{nombre(item.tramiteId)}}
            </template>

            <template v-slot:item.destino="{ item }">
                {{destino(item.neDestinoId)}}
            </template>

            <template v-slot:item.asunto="{ item }">
                {{item.asunto}}
            </template>

            <template v-slot:item.mensaje="{ item }">
                {{item.mensaje}}
            </template>

            <template v-slot:item.envia="{ item }">
                <v-simple-checkbox v-model="item.envia"></v-simple-checkbox>
            </template>

            <template v-slot:item.link="{ item }">
                <v-simple-checkbox v-model="item.link"></v-simple-checkbox>
            </template>

            
        </v-data-table>

        <v-dialog v-model="dialog"
                  max-width="1000px"
                  persistent>
            <AsuntoMensajeAdjuntoDialogo @closeDialog="dialogChange"
                                         :title='title'
                                         :asunto='asunto'
                                         :mensaje='mensaje'
                                         :direccionSoporte='direccionSoporte'
                                         @guardaInfo="changeAsuntoMensaje($event)">
            </AsuntoMensajeAdjuntoDialogo>

        </v-dialog>

    </div>
</template>
<script>
    import { mapState } from 'vuex';

    import AsuntoMensajeAdjuntoDialogo from '@/components/NE/AsuntoMensajeAdjuntoDialogo';

    export default {
        name: 'neGrid',

        props: [
            'value',
            'cliente'
        ],

        data() {
            return {
                dialog: false,
                title: "",
                asunto: "",
                mensaje: "",
                direccionSoporte:"",
                currentRow:null,
                headers: [
                    { text: 'Envia', value: 'envia' },
                    { text: 'Link', value: 'link' },
                    { text: '', value: 'icono', sortable: false },
                    { text: 'Trámite', value: 'nombre', sortable: false },
                    { text: 'Destino', value: 'destino', sortable: false },
                    { text: 'Dirección', value: 'direccionSoporte', sortable: false },
                    { text: 'Asunto', value: 'asunto', sortable: false },
                    { text: 'Mensaje', value: 'mensaje', sortable: false },
                ]
            };
        },
        computed: {
            ...mapState('cliente', ['selectedTramiteItem', 'selectedDestinoItem']),
            ...mapState('tramite', { tramitesItem: 'items' }),
            ...mapState('neDestino', { neDestinosItem: 'items' }),

            itemsSelected() {
                var self = this;
                var ls = this.value.filter(x => x.clienteId == self.cliente && x.tramiteId == self.selectedTramiteItem);
                return ls;
            },
        },

        methods: {
            dialogChange(itm) {
                if (itm !== undefined) {
                    this.asunto = itm.asunto;
                    this.mensaje = itm.mensaje;
                    this.direccionSoporte = itm.direccionSoporte;
                    this.currentRow = itm;
               }
                this.dialog = !this.dialog;
            },

            changeAsuntoMensaje(itm) {
                this.currentRow.asunto = itm.asunto;
                this.currentRow.mensaje = itm.mensaje;
                this.currentRow.direccionSoporte = itm.direccionSoporte;
            },

            icono(tramiteId) {
                //if (this.tramiteAll.loading) return '';
                if (this.tramitesItem !== undefined) {
                    var r = this.tramitesItem.find(function (itm) {
                        if (tramiteId === itm.id) {
                            return itm;
                        }
                    });
                    if (r == undefined) {
                        return '==== Tramite no definido ====';
                    }
                    //this.tramite = r;
                    return r.icono;
                }
                return '=====';
            },

            destino(neDestinoId) {
                //if (this.tramiteAll.loading) return '';
                if (this.neDestinosItem !== undefined) {
                    var r = this.neDestinosItem.find(function (itm) {
                        if (neDestinoId === itm.id) {
                            return itm;
                        }
                    });
                    if (r == undefined) {
                        return '==== destino no definido ====';
                    }
                    return r.nombre;
                }
                return '=====';
            },

            nombre(tramiteId) {
                //if (this.tramiteAll.loading) return '';
                if (this.tramitesItem !== undefined) {
                    var r = this.tramitesItem.find(function (itm) {
                        if (tramiteId === itm.id) {
                            return itm;
                        }
                    });
                    if (r == undefined) {
                        return '==== tramite no definido ====';
                    }
                    return r.nombre;
                }
                return '=====';
            },

        },
        components: {
            AsuntoMensajeAdjuntoDialogo
        }
    };
</script>

<style>
    .td {
        background-color: red;
    }
</style>
