<template>
    <clienteCrud></clienteCrud>
</template>

<script>
import clienteCrud from '@/components/Cliente/ClienteCrud';

export default {
    name: 'cliente',
    data() {
        return {};
    },
    components: {
        clienteCrud
    }
};
</script>
