<template>
    <v-container grid-list-md>
        <v-row>
            <v-layout wrap>
                <v-flex xs12 sm6 md7>
                    <multipleSelect v-model="tramitesSelected"
                                    dense
                                    :items="tramitesItem"
                                    item-text="nombre"
                                    item-value="id"
                                    attach
                                    chips
                                    label="Tramites"
                                    @change="changeTramite"
                                    @lastchange="lastTramiteChange"
                                    @selectChanged="setSelectedTramiteItem"
                                    @ItemClick="tramiteClick"
                                    multiple
                                    selectedColor="yellow"
                                    unselectedColor="yellow lighten-4"
                                    :menu-props="{ auto: true, botton: true, offsetY: true }">
                    </multipleSelect>
                </v-flex>
                <v-flex xs12 sm6 md5>
                    <!--selectedTramiteItem={{selectedTramiteItem}}
                    /selectedDestinoItem={{selectedDestinoItem}}
                    {{tramitesSelected.length}}
                    {{neDestinosSelected.length}}-->
                    <multipleSelect :disabled="tramitesSelected.length==0"
                                    v-model="neDestinosSelected"
                                    dense
                                    :items="neDestinosItem"
                                    item-text="nombre"
                                    item-value="id"
                                    attach
                                    chips
                                    label="Destinos"
                                    @change="changeDestino"
                                    @lastchange="lastDestinoChange"
                                    @selectChanged="setSelectedDestinoItem"
                                    @ItemClick="destinoClick"
                                    multiple
                                    selectedColor="yellow"
                                    unselectedColor="yellow lighten-4"
                                    :menu-props="{ auto: true, botton: true, offsetY: true }">
                    </multipleSelect>
                </v-flex>
            </v-layout>
            <template>
                <v-dialog v-model="dialog"
                          max-width="900"
                          transition="dialog-bottom-transition">
                    <AsuntoMensajeAdjuntoDialogo :title="title"
                                                 :asunto="asunto"
                                                 :mensaje="mensaje"
                                                 :direccionSoporte="direccionSoporte"
                                                 @guardaInfo="guardaTexto"
                                                 @closeDialog="dialog=false">
                    </AsuntoMensajeAdjuntoDialogo>
                </v-dialog>
            </template>
        </v-row>
    </v-container>
</template>

<script>
    // Componente para cargar Tramite y destino para enviar notificaciones
    import { mapState, mapActions } from 'vuex';
    import multipleSelect from '@/components/Selects/multipleSelect';
    import AsuntoMensajeAdjuntoDialogo from '@/components/NE/AsuntoMensajeAdjuntoDialogo';

    export default {
        name: 'neConfigura',
        props: [
            'value',
            'cliente'
        ],

        data() {
            return {
                dialog: false,
                title: '',
                asunto: '',
                mensaje: '',
                direccionSoporte:'',
                soporteMail: 1,
                soporteWA: 0
            };
        },

        computed: {
            ...mapState('cliente', ['selectedTramiteItem', 'selectedDestinoItem']),
            ...mapState('tramite', { tramitesItem: 'items' }),
            ...mapState('neDestino', { neDestinosItem: 'items' }),

            destinos() {
                return [this.sel];
            },

            tramitesSelected() {
                var self = this;
                var ls = this.value.map(x => {
                    if (x.clienteId == self.cliente)
                        return x.tramiteId
                });
                var r = ls.filter((x, i, a) => a.indexOf(x) == i);
                return r;
            },

            neDestinosSelected() {
                var self = this;
                var ls = this.value.filter(x => x.clienteId == self.cliente && x.tramiteId == self.selectedTramiteItem);
                var r = ls.map(x => { return x.neDestinoId });
                return r
            },

        },

        methods: {
            ...mapActions('cliente', [
                'insertCiaTramiteDestinoSoporte',
                'deleteCiaTramite',
                'deleteCiaTramiteDestino',
                'setSelectedTramiteItem',
                'setSelectedDestinoItem',
                'setAsuntoMensaje']),

            guardaTexto(data) {
                this.asunto = data.asunto;
                this.mensaje = data.mensaje;
                this.setAsuntoMensaje({
                    clienteId: this.cliente,
                    tramiteId: this.selectedTramiteItem,
                    neDestinoId: this.selectedDestinoItem,
                    asunto: data.asunto,
                    mensaje: data.mensaje,
                    neSoporteId: data.neSoporteId
                })
            },

            changeTramite(val) {
                console.log("Tramite valores:" + val);
            },

            changeDestino(val) {
                console.log("Destino valores:" + val);
            },

            lastTramiteChange(diff, inserted, deleted) {
                //console.log("inserted:" + inserted + "////" + "deleted:" + deleted)
                var self = this;
                var cli = this.cliente;
                inserted.map(item => {
                    self.insertCiaTramiteDestinoSoporte({
                        clienteId: cli,
                        tramiteId: item,
                        neDestinoId: 1,
                        neSoporteId: 1
                    });
                    self.setSelectedTramiteItem(item);
                    self.setSelectedDestinoItem(1);
                    //console.log("insert:" + item);
                });
                deleted.map(item => {
                    self.deleteCiaTramite({
                        clienteId: cli,
                        tramiteId: item,
                    });
                });
            },

            lastDestinoChange(diff, inserted, deleted) {
                //console.log("inserted:" + inserted + "////" + "deleted:" + deleted)
                var self = this;
                var cli = this.cliente;
                var tmt = this.selectedTramiteItem;
                inserted.map(item => {
                    self.insertCiaTramiteDestinoSoporte({
                        clienteId: cli,
                        tramiteId: tmt,
                        neDestinoId: item,
                        neSoporteId: 1
                    });
                    self.setSelectedDestinoItem(item);
                    //console.log("insert:" + item);
                });
                deleted.map(item => {
                    self.deleteCiaTramiteDestino({
                        clienteId: cli,
                        tramiteId: tmt,
                        destinoId: item,
                    });
                });
            },

            update() {

            },

            tramiteClick(/*item*/) {

            },

            destinoClick(item) {
                this.dialog = true;
                var self = this;
                var t = this.tramitesItem.find(function (itm) {
                    if (self.selectedTramiteItem === itm.id) {
                        return itm.nombre;
                    }
                });

                var d = this.neDestinosItem.find(function (itm) {
                    if (item === itm.id) {
                        return itm.nombre;
                    }
                });

                var v = this.value.find(function (itm) {
                    if (item === itm.neDestinoId) {
                        return itm;
                    }
                });

                this.title = t.nombre + " =>  " + d.nombre;
                this.asunto = v.asunto;
                this.mensaje = v.mensaje;
                this.direccionSoporte = v.direccionSoporte;
            }
        },
        created() {
        },

        components: {
            AsuntoMensajeAdjuntoDialogo,
            multipleSelect
        }

    }

</script>
